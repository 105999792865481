const initialState = {
    menuOpen: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_MENU':
            return {
                menuOpen: action.payload,
            }
        default:
            return state
    }
}

const actions = {
    toggleMenu: (openOrNot) => ({
        type: 'TOGGLE_MENU',
        payload: openOrNot,
    }),
}

export default reducer
export { actions }
