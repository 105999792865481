import React from 'react'
import uiReducer, { actions as uiActions } from './ui'
import { Provider } from 'react-redux'
import { combineReducers, createStore } from 'redux'

const rootReducer = combineReducers({ ui: uiReducer })

const store = createStore(rootReducer)

const actions = {
    ui: uiActions,
}

const StateProvider = ({ element }) => <Provider store={store}>{element}</Provider>

export default StateProvider
export { actions }
